$grid-gap: 1.5rem;

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.col {
  margin: $grid-gap / 2;

  @for $i from 1 through 12 {
    &-#{$i} {
      flex: 1;
      min-width: 100%;
    }

    @include responsive(tablet) {
      &-#{$i} {
        min-width: auto;
        flex: 0 0
          calc(
            (100% - #{$grid-gap * 11}) / 6 * #{$i} + #{$grid-gap * ($i - 1)} + #{$grid-gap *
              $i}
          );
      }
    }

    @media (max-width: $lg) {
      &-lg-#{$i} {
        min-width: auto;
        flex: 0 0
          calc(
            (100% - #{$grid-gap * 11}) / 12 * #{$i} + #{$grid-gap * ($i - 1)}
          );
      }
    }

    @media (max-width: $md) {
      &-md-#{$i} {
        min-width: auto;
        flex: 0 0
          calc(
            (100% - #{$grid-gap * 11}) / 12 * #{$i} + #{$grid-gap * ($i - 1)}
          );
      }
    }

    @media (max-width: $lgtablet) {
      &-tablet-#{$i} {
        min-width: auto;
        flex: 0 0
          calc(
            (100% - #{$grid-gap * 11}) / 12 * #{$i} + #{$grid-gap * ($i - 1)}
          );
      }
    }

    @media (max-width: $tablet) {
      &-sm-#{$i} {
        min-width: auto;
        flex: 0 0
          calc(
            (100% - #{$grid-gap * 11}) / 12 * #{$i} + #{$grid-gap * ($i - 1)}
          );
      }
    }

    @media (max-width: $mobile) {
      &-xs-#{$i} {
        min-width: auto;
        flex: 0 0
          calc(
            (100% - #{$grid-gap * 11}) / 12 * #{$i} + #{$grid-gap * ($i - 1)}
          );
      }
    }

    @include responsive(md) {
      &-#{$i} {
        min-width: auto;
        flex: 0 0
          calc(
            (100% - #{$grid-gap * 11}) / 12 * #{$i} + #{$grid-gap * ($i - 1)}
          ) !important;
      }
    }
  }
}
