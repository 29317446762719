@import "./variable";

@media (max-width: $mobile) {
  .mobile-slide-list {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    width: 100%;
    scrollbar-width: none;

    & > div {
      width: 80vw;
      min-width: 79vw;
      flex-basis: auto;

      img {
        max-height: 320px;
      }
    }
  }
}
